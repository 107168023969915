@use '../../../assets/scss/colors';

$card-content-width: 90%;
$image-container-padding: (100% - $card-content-width) / 2;

.technician-details-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 20%;

  .technician-image-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding-left: $image-container-padding;
    padding-right: $image-container-padding;
  }

  .technician-details-content {
    width: $card-content-width;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .technician-details-header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .technician-email {
        color: colors.$bluon-grey-1;
      }

      .tech-skill-level {
        margin-top: 5%;
      }
    }

    .technician-details-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: center;
      margin-top: 5%;

      .technician-bio {
        width: 100%;
        text-align: center;
      }

      .technician-details {
        width: 100%;
        margin-top: 2%;

        .technician-detail-value {
          overflow-x: hidden;
          white-space: nowrap;
          width: 100%;
          text-overflow: ellipsis;
        }

        .technician-detail-label {
          width: 100%;
          font-weight: 600;
        }
      }
    }
  }
}
