.google-map {
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  overflow: hidden;

  > * {
    position: absolute !important;
    top: 0;
    left: 0;
  }
}
