@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.account-setup-success {
  @include mixins.center-content;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: mixins.rem(40px);
    justify-items: center;

    &__title {
      display: flex;
      flex-direction: column;
      gap: mixins.rem(8px);
      text-align: left;

      &__main {
        color: colors.$bluon-black;
      }
      &__sub {
        color: colors.$bluon-grey-1;
      }
    }
  }
}
