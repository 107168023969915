.dialog-container {
  .MuiDialog-paper {
    padding: 2rem;
    border-radius: 1rem;
  }

  .dialog-actions {
    justify-content: center;
    padding-top: 2rem;
  }
}
