@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.MuiGrid-root.file-upload {
  flex-direction: column;
}

.file-upload {
  .file-upload-header {
    margin-bottom: 1rem;
  }

  .file-upload-drag-drop-space {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 1px dashed colors.$bluon-grey-3;
    padding: 1.5rem;
    height: 35vh;
    margin-bottom: 1rem;

    .file-upload-logo-container {
      @include mixins.logo-size;
      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
      border-radius: 5px;

      .file-upload-logo {
        display: block;
        border-radius: 5px;
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }

    .file-upload-text {
      margin: 0.5rem 0;

      .file-upload-button {
        margin-right: 0.25em;
        cursor: pointer;
      }
    }

    .file-upload-requirements {
      color: colors.$bluon-grey-1;
    }
  }
}
