.confirmation-dialog {
  text-align: center;

  .confirmation-dialog__actions {
    padding-top: 8px;
  }

  .confirmation-dialog__discard-btn:not(:first-child) {
    margin-left: auto !important;
  }
}
