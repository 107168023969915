.onboarding-step-one {
  .onboarding-step-one__image {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
    img {
      height: 15vh;
      object-fit: cover;
    }
  }
}

.company-terms-form {
  .company-terms-form__legend {
    margin-bottom: 14px;
    text-align: center;
    font-weight: 700;
    font-size: 1.5rem;
  }

  .company-terms-form__body {
    .MuiFormControlLabel-label {
      font-size: 0.9rem;
    }
  }
}
