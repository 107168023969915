@use '../../../assets/scss/colors';

.store-card {
  height: 100%;

  &:hover {
    background-color: colors.$bluon-light-blue;
  }
  .store-card__media {
    position: relative;
  }

  .store-card__image {
    border-radius: 4px;
    height: 300px;
  }

  .store-card__chip {
    position: absolute;
    left: 16px;
    bottom: 16px;
  }

  .store-card__phone,
  .store-card__address {
    font-style: normal;
  }

  .MuiCardContent-root {
    color: rgba(0, 0, 0, 0.6);
  }
}
