@use '../../../assets/scss/colors';

.state-handler-empty {
  width: 100%;
  min-height: 68vh;
  height: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 1em;

  .state-handler-empty__image {
    max-width: 280px;
    margin: 0 auto;
  }

  .state-handler-empty__title {
    margin-bottom: 15px;
    font-style: italic;
    font-size: 1.5rem;
    font-weight: 500;
    color: colors.$bluon-blue;
    text-align: center;
  }

  .state-handler-empty__text {
    color: colors.$bluon-grey-1;
    font-weight: 100;
  }
}
