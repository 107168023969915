.state-handler {
  width: 100%;
  display: flex;
  padding: 16px;

  &--loading {
    justify-content: center;
    align-self: center;
  }
}
