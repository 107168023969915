@use '../../../assets/scss/mixins';

.terms-of-service {
  display: flex;
  align-items: center;
  // margin: 2rem 3rem;
  .MuiTypography-body2 {
    font-weight: 700;
    font-size: mixins.rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15px;
  }

  .MuiTypography-body1 {
    font-weight: 400;
    font-size: mixins.rem(16px);
    line-height: 1.5;
    letter-spacing: 0.15px;
  }
}
