@use '../../assets/scss/mixins';

.verify-account {
  @include mixins.center-content;

  .verify-account-header {
    margin-bottom: 2rem;
  }

  .verify-account-row {
    display: flex;
    column-gap: 1rem;

    .verify-account-input-field {
      flex: 1 1 auto;
    }
  }

  .verify-account-email {
    margin: 1rem 0;
  }

  .verify-account-radio {
    margin-bottom: 2.5rem;
  }

  .verify-account-radio-text {
    opacity: 0.6;
  }

  .verify-account-radio-group {
    display: flex;
    flex-direction: row;

    .verify-account-radio-buttons {
      flex: 1 1 50%;
      margin: 0;
    }
  }

  .verify-account-buttons {
    display: flex;
    .verify-account-buttons-not-me {
      margin-right: 1rem;
    }
    .verify-account-buttons-its-me {
      flex: 1 1 auto;
    }
  }
}
