@use '../../assets/scss/mixins';

.forgot-password {
  @include mixins.center-content;

  .forgot-password-header {
    margin-bottom: 2rem;
  }

  .forgot-password-forgot-button {
    margin: 2rem 0;
  }
}
