.container-dropdown {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
}

.counter-staff-item {
  .MuiListItemIcon-root {
    justify-content: flex-end;
  }
}

.dropdown-counter-staff {
  background-color: var(--bluon--ds--selected);

  .MuiMenuItem-root.MuiMenuItem-gutters {
    background-color: var(--bluon--ds--selected);
  }

  .MuiMenuItem-root.Mui-selected {
    background-color: var(--bluon--ds--highlight);
    color: var(--bluon--ds--brand);
  }
  .dropdown-caption-title {
    .MuiTypography-root {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 0.75rem;
      padding: 0.25rem;
    }
  }
}
