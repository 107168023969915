.onboarding-step-two {
  text-align: center;

  .onboarding-step-two__image {
    width: 100%;
    padding: 88px 0 130px;

    img {
      display: block;
      width: 100%;
    }
  }
}
