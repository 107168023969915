@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.technicians-add-modal {
  .MuiDialogTitle-root {
    padding: mixins.rem(50) mixins.rem(40) mixins.rem(20);
    font-size: mixins.rem(34);
    line-height: 1.24;
    font-weight: 400;
  }

  .MuiDialogActions-root {
    justify-content: center;
    padding: mixins.rem(35) mixins.rem(40) mixins.rem(32) mixins.rem(40);
  }

  .technicians-add-modal__close-btn {
    position: absolute;
    right: mixins.rem(33);
    top: mixins.rem(20);
    color: #fff;
    background-color: colors.$bluon-grey-2;
    padding: 2px;
    opacity: 1;

    &:hover {
      background-color: colors.$bluon-blue;
      color: #fff;
    }
  }
}
.technicians-button {
  &.MuiButton-root {
    border-radius: 2rem;
  }
}
