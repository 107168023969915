.onboarding-highlight-video-header {
  text-align: center;

  .MuiTypography-h3 {
    margin-bottom: 16px;
    font-weight: 700;
  }
}

.onboarding-highlight-image-header {
  text-align: center;

  .MuiTypography-h3 {
    margin-bottom: 16px;
    font-size: 3rem;
    width: 500px;
    font-weight: 700;
  }

  .MuiTypography-h5 {
    width: 500px;
  }
}
