.store-add-form {
  .store-add-form__header {
    text-align: center;
  }

  .store-add-form__grid {
    justify-content: space-between;
    align-items: center;
  }
}
