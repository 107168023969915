@use '../../assets/scss/colors';

.MuiGrid-container.private-route {
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
  background-color: colors.$bluon-grey-4;
}

.MuiGrid-item.private-route-page {
  display: flex;
  flex-grow: 1;
  overflow: auto;
}
