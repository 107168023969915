@use '../../../assets/scss/colors';

.styled-drawer {
  > .MuiDrawer-paper {
    width: 445px;
  }

  .MuiTypography-gutterBottom {
    margin-bottom: 0.5rem;
  }

  .MuiCardHeader {
    &-action {
      margin: 0;
    }
  }

  .styled-drawer__close {
    position: absolute;
    top: 10px;
    left: 10px;
    padding: 0;
    color: colors.$bluon-white;

    &.technician-card-detail-close-button {
      color: colors.$bluon-grey-1 !important;
    }
  }
}
