@use '../../../../assets/scss/colors';
@use '../../../../assets/scss/mixins';

.onboarding-video-highlight {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiTypography-overline {
    color: colors.$bluon-blue;
    font-size: mixins.rem(15px);
    letter-spacing: mixins.em(0.46px);
  }
}
