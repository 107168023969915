@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.file-upload-progress {
  padding: 0 8px 0 16px;

  .file-upload-progress-avatar {
    background-color: colors.$bluon-light-blue;

    .file-upload-progress-icon {
      color: colors.$bluon-blue;
    }
  }

  .file-upload-progress-status {
    flex: 1 1 auto;
    padding: 0 1rem;

    .file-upload-progress-status-text {
      color: colors.$bluon-grey-1;
    }
  }

  .file-upload-progress__actions {
    align-items: center;
  }

  .file-upload-progress-cancel {
    color: colors.$bluon-grey-1;
    cursor: pointer;
  }

  .file-upload-progress-error,
  .file-upload-progress-success {
    font-size: mixins.rem(28);
  }

  .file-upload-progress-success {
    color: colors.$bluon-lime;
  }

  .file-upload-progress-error {
    color: colors.$bluon-red;
  }
}
