// Scss
@use '../../../assets/scss/colors';

$width: 28px;
$height: 40px;

// initially any map object has left top corner at lat lng coordinates
// it's on you to set object origin to 0,0 coordinates
.GoogleMapMarker {
  position: absolute;
  width: $width;
  height: $height;
  left: calc(-1 * $width / 2);
  top: calc(-1 * $height);
  color: colors.$bluon-light-error-main;
}
