@use '../../../assets/scss/colors';

.technician-invitation-sidebar {
  background: colors.$bluon-light-blue;
  box-shadow: inset 0px 1px 4px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 1.25rem;
  overflow: hidden;
  max-height: 100%;
  overflow-y: auto;
}
