@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.account-confirm-form {
  // padding-top: 1rem;
  .MuiTypography-h6 {
    font-size: mixins.rem(18px);
    color: colors.$bluon-grey-2;
  }

  .MuiTypography-body1 {
    font-weight: 300;
  }
  .MuiGrid-container {
    margin: 1rem 0;

    &.MuiGrid-root > .MuiGrid-item {
      padding-right: 1rem;
    }
  }
  .account-confirm-form__actions {
    justify-content: center;
    align-items: center;

    .MuiButton-sizeLarge {
      padding: 0.375rem 1rem;
    }
  }

  .MuiFormGroup-root {
    padding-left: 16px;
    padding-top: 8px;
  }
}

.services-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 29vw;
}
