@use '../../assets/scss/colors';

.onboarding {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: space-between;

  .onboarding-step-container {
    width: 32%;
    height: 100%;
    background-color: white;
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    justify-content: stretch;

    .onboarding-step-indicator {
      width: 100%;
      height: 28vh;
      padding: 1.8em;
      overflow: auto;

      .onboarding__header {
        margin-bottom: 1em;
        color: colors.$bluon-grey-1;

        .MuiTypography-h4 {
          font-weight: 700;
          color: colors.$bluon-blue;
          font-size: 2.125rem;
        }

        .MuiTypography-h5 {
          margin-bottom: 1rem;
          font-size: 1.4rem;
          font-weight: 300;
          color: colors.$bluon-navy;
        }
      }
    }

    .onboarding-step-form-container {
      width: 100%;
      height: 72vh;

      .onboarding-step-form {
        width: 100%;
        height: 64vh;
        overflow: auto;
        padding: 1em;
      }

      .onboarding-step-actions {
        width: 100%;
        height: 8vh;
        display: flex;
        box-shadow: 0px -10px 10px -10px rgba(0,0,0,0.2);
        align-items: center;
        justify-items: center;
        justify-content: center;
      }
    }
  }

  .onboarding-step-info {
    width: 68%;
    height: 100%;
    background-color: colors.$bluon-light-blue;
  }
}
