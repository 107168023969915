.store-details {
  overflow: visible;

  .store-details__header {
    padding-bottom: 0;
  }

  .store-details__edit-btn {
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px rgba(0, 0, 0, 0.14), 0px 1px 18px rgba(0, 0, 0, 0.12);
  }

  .store-details__content {
    padding-top: 0;
  }

  .store-details__content-meta,
  .store-details__content-address {
    color: rgba(0, 0, 0, 0.6);
  }

  .store-details__content-meta {
    font-weight: 500;
  }

  .store-details__content-address {
    margin-bottom: 20px;
    font-style: normal;
  }
}
