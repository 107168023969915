@use '../../../assets/scss/colors';

.text-field-input {
  input:-webkit-autofill {
    transition-delay: 9999s;
    transition-property: background-color, color;
  }
  input {
    border-color: colors.$bluon-grey-1;
  }
}
