@use '../../../assets/scss/colors';

.store-update-form-actions {
  > .MuiGrid-container {
    justify-content: center;
    text-align: center;
  }
}

.store-update-form-title {
  p,
  h5 span {
    color: rgba(0, 0, 0, 0.6);
  }
}
