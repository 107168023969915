.upcoming-feature-modal {
  .MuiDialog-paper {
    padding: 24px 24px 32px;
  }

  .MuiDialogTitle-root {
    text-align: center;
  }

  .MuiDialogContent-root {
    padding: 8px 50px;
  }

  .MuiDialogTitle-root h2 {
    text-align: center;
    font-weight: 600;
    margin-bottom: 8px;
  }

  .MuiDialogTitle-root h4 {
    text-align: center;
  }
}
