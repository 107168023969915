@use '../../assets/scss/colors';
@use '../../assets/scss/mixins';

.link-expired {
  @include mixins.center-content;

  .link-expired-header,
  .link-expired-warning-text {
    text-align: center;
  }

  .link-expired-header {
    margin-bottom: 1rem;
  }

  .link-expired-warning-text {
    margin-bottom: 2rem;
  }
}
