@use '../../../assets/scss/colors';

.store-search-list {
  &-empty {
    background-color: colors.$bluon-bg-grey;
  }

  &-divider.MuiDivider-root {
    border-color: colors.$bluon-grey-2;
  }
}
