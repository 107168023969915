@use '../../../assets/scss/colors';

.login-layout {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$bluon-navy;
  height: 100%;

  &-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 325px;
    color: colors.$bluon-white;
  }

  &-text {
    text-align: center;
    margin-bottom: 40px;
    h2 {
      font-size: 1.25rem;
      font-weight: 500;
      margin-bottom: 11px;
    }
  }
}
