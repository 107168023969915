@use '../../assets/scss/colors';
@use '../../assets/scss/mixins';

.link-sent {
  @include mixins.center-content;

  .link-sent-header,
  .link-sent-subheader {
    text-align: center;
  }

  .link-sent-header {
    margin-bottom: 0.5rem;
  }

  .link-sent-subheader {
    color: colors.$bluon-grey-1;
  }
}
