@use '../../assets/scss/mixins';
@use '../../assets/scss/colors';

.reset-password {
  @include mixins.center-content;

  .reset-password-header {
    margin-bottom: 2rem;
  }

  .reset-password-input-field {
    margin-bottom: 0.4rem;
  }

  .reset-password-new-password {
    margin-bottom: 1rem;
  }

  .password-text {
    margin-bottom: 1em;
    margin-left: 1.2em;
    font-size: 0.75rem;
    font-weight: 300;
    color: colors.$bluon-grey-1;
  }
}
