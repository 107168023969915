@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.finish-account-setup {
  @include mixins.center-content;

  &__title {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: mixins.rem(40px);

    &__main {
      color: colors.$bluon-black;
    }

    &__sub {
      color: colors.$bluon-grey-1;
    }
  }

  &__email__field {
    margin-bottom: 3rem;
  }

}
