@use '../../assets/scss/colors';
@use '../../assets/scss/mixins';

.mobile-notification {
  text-align: center;

  .mobile-notification__body {
    justify-content: center;
    padding: 120px 20px 124px;
    min-height: 100vh;
  }

  .MuiTypography-h3 {
    font-size: mixins.rem(41px);
    color: colors.$bluon-blue;
    font-weight: 800;
    letter-spacing: -0.036em;
  }

  .MuiTypography-body1 {
    font-size: mixins.rem(20px);
    line-height: 1.6;
    font-weight: 500;
  }

  .mobile-notification__logo {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    padding-top: 16px;
    padding-bottom: 52px;
    background-color: colors.$bluon-white;

    img {
      width: 92px;
    }
  }
}
