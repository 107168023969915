@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.onboarding-techs-actions {
  width: 100%;
  height: 7vh;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}

.onboarding-techs-list {
  width: 100%;
  height: 45vh;
  overflow: auto;
  display: flex;
  justify-content: center;

  .onboarding-techs-list-items {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
    justify-items: center;
    grid-template-rows: auto auto 1fr 1fr 1fr auto auto;

    .MuiChip-root {
      border-radius: 2rem;
    }

    .MuiChip-labelMedium {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 1rem;
      line-height: 150%;
      letter-spacing: 0.15px;
    }

    .MuiChip-filled {
      background: colors.$bluon-blue-light;
      box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 1px 1px rgba(0, 0, 0, 0.14), 0px 1px 3px rgba(0, 0, 0, 0.12);
    }
  }
}

.onboarding-techs-info {
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  margin-top: 1em;
}
