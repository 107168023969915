@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.video {
  position: relative;
  display: flex;
  justify-content: center;

  .video__container {
    position: relative;
    padding-bottom: 56.25%;
    background-color: colors.$bluon-grey-1;
    border-radius: 16px;
    overflow: hidden;
    width: 55vw;

    video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  .MuiIconButton-root {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 10;
    transform: translate(-50%, -50%);
    color: colors.$bluon-white;
    font-size: mixins.rem(155);
    transition: visibility 0.3s cubic-bezier(0.4, 0, 0.6, 1);
  }

  .MuiIconButton-root, .MuiIconButton-root:hover  {
    color: colors.$bluon-blue;
    font-size: 6rem;
  }

  &.is-playing,
  &.is-touched {
    .MuiIconButton-root {
      visibility: hidden;
    }
  }
}
