@use '../../assets/scss/colors';

.technicians {
  width: 100%;
  flex-direction: column;
  display: flex;

  .technicians-container {
    display: flex;
    flex-direction: row;
    gap: 15px;
  }

  .technicians__list {
    padding: 35px 25px;
    border-radius: 10px;
    background-color: colors.$bluon-white;
    flex-basis: 72%;
    flex-shrink: 1;
    flex-grow: 1;
  }

  .technicians__top-bar {
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
  }

  .technicians__search {
    width: 100%;
    max-width: 363px;
    padding-right: 16px;
    padding-left: 24px;
  }

  .technicians__empty {
    text-align: center;
    padding: 60px 0;
    width: 100%;
    height: 500px;
    background-color: white;
    color: colors.$bluon-white;
    font-size: 30px;
  }

  .technicians__empty-title {
    font-weight: 600;
    font-style: italic;
  }
}

.techs-container {
  padding: 1rem;
  display: flex;
  flex-flow: column;
  height: 100%;
  width: 100%;

  .header-container {
    margin-bottom: 1rem;
    gap: 2rem;
    display: flex;
    align-items: center;
    flex: 0 1 auto;
  }

  .content-container {
    height: calc(100vh - 12rem);
  }

  .MuiGrid-root > .MuiGrid-item {
    padding-top: 0;
  }
}
