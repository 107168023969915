@use '../../assets/scss/colors';

.account {
  width: 100%;
  margin-top: 1rem;
  padding: 1rem;
  justify-content: center;
  height: 100%;

  .account-page {
    flex-direction: column;
    border-radius: 10px;
    padding: 1.5rem;
    margin: 1rem 0;
    background-color: colors.$bluon-white;
    filter: drop-shadow(-5px 5px 20px colors.$bluon-drop-shadow);

    .account-header {
      margin-bottom: 1rem;
    }
  }
}
