.onboarding-image-highlight {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  width: 100%;
  height: 100%;

  .onboarding-title-subtitle {
    position: absolute;
    top: 10vh;
    left: 36vw;
  }

  .onboarding-image-highlight__media img {
    display: block;
    height: 95vh;
  }
}
