@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.technician-card {
  // Alert
  margin: 0.5rem;
  .technician-card__alert {
    justify-content: center;
    padding-top: mixins.rem(8);
    padding-bottom: mixins.rem(8);

    &.MuiAlert-standard {
      color: colors.$bluon-grey-1;
      background-color: colors.$bluon-bg-grey;
    }

    .MuiAlert-message {
      padding: 0;
      font-size: mixins.rem(10);
    }
  }

  .MuiTypography-root {
    text-transform: capitalize;
  }

  .MuiCardHeader-action {
    align-self: center;
  }
}

.technician-card__tooltip {
  &.MuiTooltip-tooltip {
    background-color: colors.$bluon-blue;
  }
  .MuiTooltip-arrow {
    color: colors.$bluon-blue;
  }
}
