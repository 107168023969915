@use '../../../assets/scss/colors';

.login-loader {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: colors.$bluon-white;
  z-index: 9999;
}
