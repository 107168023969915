@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.technician-invitation-card {
  margin: 1rem 0;
  .MuiCardContent-root {
    padding: 0.5rem 1rem;
    height: 80px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &:last-child {
      padding-bottom: 0.5rem;
    }

    .MuiTypography-body1 {
      color: colors.$bluon-dark-navy;
    }

    .MuiTypography-body2 {
      color: colors.$bluon-grey-1;
    }
  }

  .MuiCardActions-root {
    padding: 4px;

    .MuiButtonBase-root {
      font-size: mixins.rem(13px);
    }

    &.MuiButton-textSuccess {
      color: colors.$bluon-green;
    }
    &.MuiButton-textError {
      color: colors.$bluon-red;
    }
  }
}
