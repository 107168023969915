.account-form {
  .account-field-container:last-child {
    margin-bottom: 1.5rem;
  }

  .account-field-container {
    gap: 1rem;
    margin-top: 1.5rem;

    .account-field-input {
      flex: 1 1 32%;
    }
  }

  .account-company-services {
    flex-direction: column;

    .account-company-services-no-error {
      visibility: hidden;
    }

    .account-check-boxes {
      width: 250px;
      padding: 1rem;
    }
  }

  .account-form-buttons {
    justify-content: center;
    gap: 1rem;
  }
}
