.company-upload-logo-form {
  height: 100%;
  .company-upload-logo-form__actions {
    justify-content: center;
    align-items: center;

    .MuiButton-sizeLarge {
      padding: 0.375rem 1rem;
    }
  }

  .file-upload-container {
    margin: 3rem 0;
  }
}
