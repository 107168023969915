@use '../../../assets/scss/colors';

.tech-skill-level {
  gap: 7px;

  .MuiTypography-root {
    line-height: 0;
  }

  .tech-skill-level__icon {
    color: colors.$bluon-grey-2;

    &.is-active {
      color: colors.$bluon-gold;
    }
  }
}
