@use '../../../assets/scss/colors';

.store-search-card {
  display: flex;
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: colors.$bluon-bg-grey;
  }

  &.is-selected {
    background-color: colors.$bluon-light-green;
  }

  .store-search-card__media {
    width: 70px;
  }

  .store-search-card__logo,
  .store-search-card__image {
    width: 100%;
    border-radius: 4px;
    overflow: hidden;
  }

  .store-search-card__image {
    height: 55px;
    margin-bottom: 4px;
  }

  .store-search-card__logo {
    height: 18px;
    padding: 0 4px;
  }

  .store-search-card__body {
    position: relative;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    padding-left: 10px;
  }

  .store-search-card__content {
    flex: 1 0 auto;
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.MuiCardContent-root:last-child {
      padding-bottom: 0;
    }
  }

  .store-search-card__name {
    font-size: 16px;
    line-height: 1.5;
    font-weight: 400;
    color: colors.$bluon-navy;
  }

  .store-search-card__address {
    font-weight: 500;
    font-style: normal;
    color: colors.$bluon-grey-1;
  }

  .store-search-card__meta {
    display: flex;
    flex-shrink: 0;
    flex-direction: column;
    align-items: flex-end;
    .MuiChip-root {
      border-radius: 2rem;
    }
  }

  .store-search-card__distance {
    margin-top: auto;
    color: colors.$bluon-grey-1;
  }
}
