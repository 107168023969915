@use '../../../assets/scss/colors';

.styled-dialog {
  .MuiDialog-paper {
    width: 100%;
    max-width: 662px;
    padding: 1rem;
    padding-top: 2.5rem;
    border-radius: 10px;

    .MuiDialogContent-root {
      padding: 20px 24px;
    }
  }

  h2.MuiDialogTitle-root {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.33;
  }

  .MuiDialogActions-root {
    justify-content: center;
    padding: 2rem 1rem 1rem 1rem;
  }

  .styled-dialog-close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 0;
    margin: 8px;
    color: colors.$bluon-grey-2;

    &:hover {
      color: colors.$bluon-grey-1;
    }
  }
}
