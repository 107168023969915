@use '../../../assets/scss/colors';

.login-form-header-container {
  .login-form-header {
    margin-bottom: 0.5rem;
  }

  .login-form-subheader,
  .login-form-note {
    font-weight: 300;
    color: colors.$bluon-grey-1;
  }
  .login-form-note {
    font-style: italic;
  }

  .login-form-warning-note {
    color: colors.$bluon-light-error-main;
  }
}
