@use '../../../../../assets/scss/colors';
@use '../../../../../assets/scss/mixins';

.company-confirm-techs-form-empty {
  height: 40vh;
  .company-confirm-techs-form-empty__box {
    padding: 40px 16px;
    color: colors.$bluon-blue;
    border: 1px solid colors.$bluon-blue;
    background-color: colors.$bluon-light-blue;
    text-align: center;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 2rem;
  }

  &__actions {
    text-align: center;
  }
}
