@use '../../../assets/scss/colors';

.search-input {
  position: relative;
  border-radius: 4px;
  margin-left: 0;
  width: 280px;
  height: 54px;
  background-color: colors.$bluon-white;

  .search-input__icon-wrapper {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding: 0px 8px;
    pointer-events: none;
  }

  .search-input__input-base {
    width: 100%;
    color: inherit;
    .MuiInputBase-input {
      padding: 0 0.75rem;
      max-width: 100%;
      height: 54px;
      border: 1px solid colors.$bluon-grey-2;
      border-radius: 4px;
    }
  }
}
