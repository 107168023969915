@use '../../../assets/scss/colors';

.stepper {
  span {
    display: block;
    height: 8px;
    border-radius: 4px;
    border: 0;
    background-color: colors.$bluon-light-blue;

    &.is-active {
      background-color: colors.$bluon-blue;
    }
  }
}
