@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.technicians-add-form {
  padding-top: mixins.rem(20px);

  .technicians-add-form__body {
    max-width: mixins.rem(350px);
    margin-left: auto;
    margin-right: auto;
  }

  .technicians-add-form__entry {
    color: colors.$bluon-grey-1;
    padding-top: mixins.rem(40px);
  }

  .technicians-add-form__field {
    .MuiOutlinedInput-input {
      color: colors.$bluon-black;
    }
    .MuiOutlinedInput-root {
      color: colors.$bluon-grey-1;
    }

    .MuiInputLabel-root {
      color: colors.$bluon-grey-1;
    }

    .MuiInputLabel-root.Mui-focused {
      color: colors.$bluon-blue;
    }
    .MuiInputLabel-root.Mui-error {
      color: colors.$bluon-dark-red;
    }
  }
}
