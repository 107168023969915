@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.login-form {
  @include mixins.center-content;
  .login-form {
    &-header-container {
      margin-bottom: 2rem;
    }

    &-header {
      margin-bottom: 0.5rem;
    }

    &-email-field {
      margin-bottom: 1rem;
    }

    &-login-button {
      margin-top: 2rem;
      margin-bottom: 1rem;
      padding: 9px 22px;
    }

    &-forgot-password {
      text-align: center;
    }

    &-divider {
      padding-bottom: 1rem;

      &.MuiDivider-root {
        &::before {
          border-top: 1px solid rgba($color: colors.$bluon-black, $alpha: 0.38);
        }
        &::after {
          border-top: 1px solid rgba($color: colors.$bluon-black, $alpha: 0.38);
        }
      }
      &-text {
        color: rgba($color: colors.$bluon-black, $alpha: 0.38);
        font-size: mixins.rem(15px);
        line-height: mixins.rem(26px);
      }
    }

    &-first-time-access {
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-items: center;
      gap: 0.5rem;
      margin-bottom: 1.5rem;
      &-text {
        color: colors.$bluon-black;
      }
    }
  }

  .password-text {
    margin-top: 0.4em;
    margin-bottom: 1em;
    margin-left: 1.2em;
    font-size: 0.75rem;
    font-weight: 300;
    color: colors.$bluon-grey-1;
  }
}
