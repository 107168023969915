$bluon-white: #ffffff;
$bluon-black: #000000;

$bluon-dark-navy: #253342;
$bluon-navy: #304050;
$bluon-blue: #0567d0;
$bluon-light-blue: #ecf2ff;
$bluon-hover-row: #cde1f6;
$bluon-nav-ghost: #fafcfe;

$bluon-grey-1: #979797;
$bluon-grey-2: #bcbcbc;
$bluon-grey-3: #efefef;
$bluon-grey-4: #f5f5f5;
$bluon-bg-grey: #f4f5f7;
$bluon-light-grey: #f9f9f9;

$bluon-red: #de360c;
$bluon-salmon: #ff8765;
$bluon-light-red: #fcebe7;
$bluon-green: #00b246;
$bluon-light-green: #e3f7eb;
$bluon-gold: #ffb44c;
$bluon-light-gold: #fffbed;

$bluon-yellow: #ffc835;
$bluon-cyan: #86d0e7;
$bluon-coral: #eb8383;
$bluon-lime: #53c737;

$bluon-blurred-blue: rgba(#007bff, 0.75);
$bluon-blurred-white: rgba(#ffffff, 0.5);

$bluon-drop-shadow: rgba(#000000, 0.25);

$bluon-light-error-main: #d32f2f;
$bluon-dark-red: #dd3217;

$bluon-blue-light: #ddeeff;
