@use 'sass:math';
@use './colors';

// login pages (includes reset password and create account)
@mixin center-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

@mixin warning-text {
  font-size: 0.75rem;
  color: colors.$bluon-light-error-main;
  line-height: 20px;
  letter-spacing: 0.4px;
}

@function stripUnit($value) {
  @return math.div($value, $value * 0 + 1);
}

@function em($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit(16))}em;
}

@function rem($pxValue) {
  @return #{math.div(stripUnit($pxValue), stripUnit(16))}rem;
}

@mixin logo-size {
  height: 70px;
  width: 246px;
}
