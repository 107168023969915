@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.login-locked {
  @include mixins.center-content;

  .login-locked-grid {
    text-align: center;

    .login-locked-message {
      margin: 1rem 0 2rem;
      color: colors.$bluon-light-error-main;
    }
  }
}
