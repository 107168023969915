.login-route {
  height: 100vh;

  .login-route-page-container {
    position: relative;
    flex-direction: column;
  }

  &-page {
    flex: 1 1 auto;
  }

  &-terms-of-service.specific-terms-of-service {
    display: flex;
    flex-direction: row-reverse;
    margin: 2rem 3rem;
  }
}
