@use '../../../assets/scss/colors';
@use '../../../assets/scss/mixins';

.email-not-in-system {
  @include mixins.center-content;

  &__container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-bottom: mixins.rem(40px);
    justify-items: center;

    &__title {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      text-align: left;

      &__main {
        color: colors.$bluon-black;
      }
      &__sub {
        color: colors.$bluon-grey-1;
      }
    }

    &__call_info {
      color: rgba($color: colors.$bluon-blue, $alpha: 0.8);
      text-align: center;
    }
  }
}
