@use '../../../assets/scss/colors';

.store-details-rep-card {
  .store-details-rep-card__title {
    margin-bottom: 8px;
    text-align: center;
    letter-spacing: 0.15px;
    color: rgba(0, 0, 0, 0.6);
  }
}
