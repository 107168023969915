.bluon-menu-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0.35rem 0rem;

  .MuiSvgIcon-root {
    color: var(--bluon--ds--disabled);
    margin: 0.15rem 2rem;
  }

  .MuiTypography-root {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.875rem;
    text-transform: capitalize;
    color: var(--bluon--ds--disabled);
  }
}

.bluon-route-active {
  background: var(--bluon--ds--selected);
  border-radius: 0.25rem;

  .MuiSvgIcon-root {
    color: var(--bluon--ds--black);
  }

  .MuiTypography-root {
    color: var(--bluon--ds--black);
  }
}

.bluon-badge {
  .MuiBadge-badge {
    background-color: var(--bluon--ds--red);
    color: var(--bluon--ds--white);
    border-radius: 0.25rem;
    height: 1rem;
    min-width: 1rem;
    max-width: 1.7rem;
    top: 0.7rem;
    right: 1.438rem;
  }
}
